.infoCard {
  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 8px;
  background: rgba(64, 156, 255, 0.1);
  min-width: 150px;
  &__title {
    display: flex;
    gap: 4px;
    align-items: center;
    &--indicator {
      width: 8px;
      height: 8px;
      border-radius: 2px;
    }
    &--green {
      background: #27ae60;
    }
    &--orange {
      background: #f99e2c;
    }
    &--blue {
      background: #409cff;
    }
    &--red {
      background: #f64747;
    }
    &--grey {
      background: var(--theme-color-grey-4);
    }
    &--purple {
      background-color: #9b51e0;
    }
  }
  .color-green {
    color: #27ae60;
  }
  .color-red {
    color: #f64747;
  }
  .color-silver {
    color: #9b9daa;
  }
}

.skeleton {
  background-color: rgba(0, 0, 0, 0.05);
  &__indicator,
  &__icon,
  &__text {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    animation: skeleton-loading 1.2s infinite;
  }
  &__indicator {
    width: 8px;
    height: 8px;
  }
  &__icon {
    width: 16px;
    height: 16px;
  }
  &__text {
    height: 14px;
    &--caption {
      width: 60px;
      margin-top: 4px;
    }
    &--title {
      width: 100px;
      height: 24px;
      margin-bottom: 8px;
    }
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(0, 0, 0, 0.1);
  }
  50% {
    background-color: rgba(0, 0, 0, 0.2);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
