.menu-wrap {
  position: relative;
  margin-top: 4px;
  outline: none !important;
  .dropdown-menu {
    border-radius: 12px !important;
    border: 0.5px solid var(--Light-mode-Grey-02, #d7d8e0) !important;

    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15) !important;
    padding: 8px !important;
  }
  &.bottom {
    .menu {
      position: absolute;
      top: 100%;
      right: 0;
    }
  }

  &.top {
    .menu {
      position: absolute;
      bottom: 100%;
      right: 0;
    }
  }

  .menu {
    width: 100%;
    min-width: 168px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px !important;
    border: 0.5px solid var(--theme-color-grey-2);
    background-color: var(--theme-color-bg);
    box-shadow: var(--theme-box-shadow-dropdown);
    z-index: 2400;

    &__item {
      display: flex;
      padding: 10px 8px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--theme-color-grey-1);
      }
    }
  }
}
.dropdown-toggle::after {
  display: none;
}
.menu-wrap {
  position: relative;
}

.menu-wrap.bottom .dropdown-menu {
  top: 100%;
}

.menu-wrap.top .dropdown-menu {
  bottom: 100%;
}
