.feedback {
  &__header {
    padding: 22px 24px 14px 24px;
  }
  @media (max-width: 768px) {
    &__button {
      margin-right: 0 !important;
      padding-right: 0 !important;
      &--text {
        display: none;
      }
    }
  }
}
.mr-0 {
  margin-right: 0 !important;
}
.unread {
  display: inline-block;
  padding: 0 8px;
  align-items: center;
  border-radius: 12px;
  background: var(--main-color);
  color: #fff;
  margin-left: 12px;
}
.unarchive-icon {
  filter: brightness(100);
}
.feedbacks-table-wrapper {
  @media (max-width: 700px) {
    overflow-x: scroll;
    overflow-y: visible;
    height: calc(100vh - 200px);
  }
}
.delete_answer {
  button {
    color: #f64747;
  }
  margin-left: auto;
}
.color-grey-4 {
  color: var(--Light-mode-Grey-04);
}
