.navbar {
  height: 64px;
  flex-shrink: 0;
  border-bottom: 0.5px solid var(--Light-mode-Grey-02);
  background: var(--Light-mode-White);
  padding: 0 24px;
  @media (max-width: 1280px) {
    background: var(--Light-mode-Grey-01);
    border: none;
    &__dock-icon {
      display: none;
    }
  }
  &__profile {
    margin-left: auto;
    display: flex;
    gap: 12px;
    @media (max-width: 1280px) {
      display: none;
    }
    &--title {
      display: flex;
      flex-direction: column;
      &-caption {
        color: #797b8d;
      }
    }
  }
  &__navigation {
    display: none;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1280px) {
      display: flex;
    }
  }
}
