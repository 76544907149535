.sidebar {
  display: flex;
  width: 240px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  flex-direction: column;
  gap: 16px;
  flex-shrink: 0;
  border-right: 0.5px solid var(--Light-mode-Grey-02);
  background: var(--Light-mode-Grey-01);
  transition: transform 0.3s ease;
  z-index: 1001;

  &__profile {
    margin-left: auto;
    display: none;
    gap: 12px;
    padding: 16px 12px 0px 12px;
    margin-bottom: 24px;
    @media (max-width: 1280px) {
      display: flex;
    }
    &--title {
      display: flex;
      flex-direction: column;
      &-caption {
        color: #797b8d;
      }
    }
  }

  @media (max-width: 1280px) {
    transform: translateX(-100%);
    display: block;
    margin-top: 64px;
    &.open {
      transform: translateX(0);
      position: absolute;
      overflow: hidden;
    }
  }

  &.closed {
    width: 68px;
  }

  &.open {
    width: 240px;
  }
}
.sidebar-overlay {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: block;

  @media (min-width: 1280px) {
    display: none;
  }
}
.sidebar-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  @media (min-width: 1280px) {
    display: none;
  }
}

// .sidebarHeader {
// }

.sidebarMenu {
  padding: 0 12px;
  cursor: pointer;
}
.sidebarSubMenuHead,
.sidebarSubMenuHeadActive {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  color: var(--Light-mode-Black);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.sidebarSubMenuHeadActive {
  border-radius: 8px;
  background: var(--Light-mode-White);
  font-weight: 600;
}

.sidebar-dropdown {
  margin: 6px 12px;
  .dropdown-item.active,
  .dropdown-item:active {
    background-color: transparent;
    color: #212529 !important;
  }
  &__logo {
    height: 32px;
  }
  &__toggle {
    width: 100%;
    padding: 0;
    background: #f5f5f8 !important;
    mix-blend-mode: multiply;
    border: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
    padding: 10px 12px;
  }

  &__menu {
    width: 100%;
    margin-top: 6px;
    padding: 12px 8px;
    border: 0;
    box-shadow: 0px 40px 100px 0px rgba(0, 0, 0, 0.15);

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      padding: 10px 8px;
      &-icon {
        margin-left: auto;
      }
    }
  }
}
