@use "src/assets/scss/abstracts/mixins";
@use "src/assets/scss/abstracts/variables";

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}
.buttonG {
  height: 40px;
  @include mixins.color(var(--theme-color-primary-text));
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 12px;
  line-height: 20px;
  font-size: 14px;
  white-space: nowrap;
  border: 1px solid var(--theme-color-grey-2) !important;
  @include mixins.border-color(var(--theme-color-grey-2));

  @include mixins.bg-color(var(--theme-color-bg));
  @include mixins.box-shadow(var(--theme-box-shadow));

  transition: all 0.2s;

  &,
  &:link,
  &:visited,
  &[type="button"] {
    cursor: pointer;
    text-decoration: none;
    border-radius: variables.$border-radius;
  }

  &:disabled,
  &[disabled] {
    @include mixins.color(var(--theme-color-grey-3));
    @include mixins.box-shadow(none);
    cursor: no-drop;
    pointer-events: none;
  }

  &:hover {
    @include mixins.bg-color(var(--theme-color-grey-1));
  }

  &:active {
    @include mixins.bg-color(var(--theme-color-grey-2));
    @include mixins.box-shadow(none);
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }

  &--main,
  &--main[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-main);
    border: none !important;

    &:hover {
      @include mixins.bg-color(rgba(29, 114, 233, 0.9));
    }

    &:focus {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(#da8213, 0.2));
    }

    &:active {
      @include mixins.bg-color(rgba(29, 114, 233, 0.8));
      @include mixins.box-shadow(0px 0px 0px 4px rgba(#da8213, 0.2));
    }
  }

  &--green,
  &--green[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-green-2);
    border: none !important;

    &:hover {
      @include mixins.bg-color(variables.$color-green-2__hover);
    }

    &:focus {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(13, 156, 36, 0.2));
    }

    &:active {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(13, 156, 36, 0.2));
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%
        ),
        variables.$color-green-2__hover;
    }
  }

  &--blue,
  &--blue[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-blue-3);
    border: none !important;

    &:hover {
      @include mixins.bg-color(variables.$color-blue-3__hover);
    }

    &:focus {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(32, 120, 215, 0.2));
    }

    &:active {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(32, 120, 215, 0.2));
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%
        ),
        variables.$color-blue-3__hover;

      box-shadow: 0px 0px 0px 4px rgba(32, 120, 215, 0.2);
    }
  }

  &--purple,
  &--purple[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-purple);
    border: none !important;

    &:hover {
      @include mixins.border-color(variables.$color-purple__hover !important);
    }

    &:focus {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(155, 81, 224, 0.2));
    }

    &:active {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%
        ),
        variables.$color-purple__hover;

      @include mixins.box-shadow(0px 0px 0px 4px rgba(155, 81, 224, 0.2));
    }
  }

  &--red,
  &--red[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-red);
    border: none !important;

    &:hover {
      @include mixins.bg-color(variables.$color-red__hover);
    }

    &:focus {
      @include mixins.box-shadow(0px 0px 0px 4px rgba(209, 60, 60, 0.2));
    }

    &:active {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.1) 0%,
          rgba(0, 0, 0, 0.1) 100%
        ),
        variables.$color-red__hover;
      @include mixins.box-shadow(0px 0px 0px 4px rgba(209, 60, 60, 0.2));
    }
  }

  &--black,
  &--black[type="button"] {
    @include mixins.color(variables.$color-white !important);
    @include mixins.bg-color(variables.$color-grey-2-dark);
    @include mixins.box-shadow(0px 1px 3px 0px rgba(20, 22, 41, 0.1));
    border: none !important;

    &:hover {
      @include mixins.bg-color(variables.$color-grey-1-dark);
    }

    &:active {
      @include mixins.bg-color(#15171f);
    }
  }

  &--before {
    padding-right: 16px !important;
  }
  &--after {
    padding-left: 16px !important;
  }
  &--block {
    width: 100%;
  }

  &--large {
    padding: 0 14px;
    height: 48px;
  }

  &--sm {
    padding: 0 10px;
    height: 36px;
  }

  &--xs {
    padding: 0 10px;
    height: 32px;
  }

  &--square {
    padding: 0 8px;
    height: 48px;
  }

  &__before,
  &__after {
    display: flex;
    color: currentColor;
  }

  &__before {
    margin-right: 8px;
  }

  &__after {
    margin-left: 8px;
  }
}

.buttonG-icon {
  height: 40px;
  width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: variables.$border-radius;
  transition: all 0.2s;

  &,
  &:link,
  &:visited,
  &[type="button"] {
    border: none;
    cursor: pointer;
    text-decoration: none;
    @include mixins.color(var(--theme-color-grey-3));
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
  }

  &:hover {
    @include mixins.bg-color(var(--theme-color-grey-1));
  }

  &:active {
    @include mixins.bg-color(var(--theme-color-grey-2));
  }

  &:disabled,
  &[disabled] {
    @include mixins.color(var(--theme-color-grey-2));
    @include mixins.box-shadow(none);
    cursor: no-drop;
    pointer-events: none;
  }
}
