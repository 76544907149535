@use "src/assets/scss/abstracts/mixins";
@use "src/assets/scss/abstracts/variables";

.drawer-view {
  @include mixins.bg-color(var(--theme-color-bg));
  height: 100%;
  display: flex;
  flex-direction: column;

  &__loading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    @include mixins.bg-color(var(--theme-color-bg));
    flex-shrink: 0;
    z-index: 1;
    padding: 20px 24px;
  }

  &__body {
    flex: 1;
    overflow-y: auto;
  }

  &__footer {
    @include mixins.bg-color(var(--theme-color-bg));
    flex-shrink: 0;
    z-index: 1;
    border-top: 1px solid var(--theme-color-grey-2);
    padding: 20px 24px;
  }
}

.navigation-drawer {
  @include mixins.bg-color(var(--theme-color-bg));
  @include mixins.box-shadow(-40px 0px 100px 0px rgba(0, 0, 0, 0.15));
  border-left: 1px solid var(--theme-color-grey-2);
  @include mixins.border-color(var(--theme-color-grey-2));

  &__overlay .v-overlay__scrim {
    @include mixins.bg-color(var(--theme-color-grey-5) !important);
    opacity: 0.4 !important;
  }
}
