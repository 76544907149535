.inputs {
  input {
    color: #9b9daa;
  }
  &:hover {
    input {
      color: var(--theme-color-primary-text);
    }
  }
  &__buttons {
    display: none !important;
  }
  &:hover > &__buttons {
    display: flex !important;
  }
}
