.feedbacks {
  &__date {
    display: flex;
    padding: 6px 24px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-top: 0.5px solid var(--Light-mode-Grey-02);
    border-bottom: 0.5px solid var(--Light-mode-Grey-02);
    background: var(--Light-mode-Grey-01);

    &-text {
      color: var(--Light-mode-Grey-04);
    }
  }

  &__list {
    &-item {
      padding: 0 24px;
      display: flex;
      align-items: center;
      flex-direction: row;
      column-gap: 12px;
      cursor: pointer;
      &-unread {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        div {
          width: 8px;
          height: 8px;
          background-color: var(--main-color);
          border-radius: 50%;
        }
      }

      &-company {
        display: flex;
        flex: 1;
        align-items: center;
        column-gap: 12px;
        border-bottom: 0.5px solid var(--Light-mode-Grey-02);
        padding: 12px 0;
        &-titles {
          flex: 1;

          &-date {
            color: var(--Light-mode-Grey-04);
            font-size: 13px;
            margin-top: 4px;
          }
        }

        &-icon {
          margin-left: auto;
        }
        &--user-data {
          display: inline-flex;
          flex-direction: column;
          padding: 4px 8px;
          border-radius: 8px;
          border: 0.5px solid var(--Light-mode-Grey-02);
          background: var(--Light-mode-Grey-01);
          margin-top: 8px;
          .font-weight-bold {
            font-weight: 600;
          }
          .company-user {
            display: inline-flex;
            align-items: center;
            column-gap: 4px;
            flex-wrap: wrap;
          }
          .device-info {
            margin-left: 24px;
            color: var(--Light-mode-Grey-04);
          }
        }
      }
    }
  }
}
.statistics {
  padding: 0 24px 24px 24px;
}
