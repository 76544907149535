.select-menu {
  padding: 8px;
}
.dropdown-item-custom {
  border-top: 1px solid #D7D8E0;
}
.dropdown-item {
  background-color: white !important;
}
.react-date-range * {
  box-sizing: border-box;
}
.react-date-range {
  max-width: 240px;
  width: 100%;
  overflow: hidden;
  
  .rdrMonth {
    width: 100%;
    padding: 0;
  }

  .rdrCalendarWrapper {
    max-width: 240px;
  }

  .rdrDateRangePickerWrapper {
    max-width: 240px;
  }

  .rdrMonthAndYearWrapper {
    display: flex;
    justify-content: center;
    .rdrNextPrevButton {
      margin: 0;
    }
  }

  .rdrDay {
    font-size: 12px;
  }
}